<article class="app-icon-container">
  <a class="select-none" (click)="handleClick()">
    @defer (on viewport) {
      <img
        [ngClass]="{
          'big-image': displayType === DisplayType.BIG,
          'small-image': displayType === DisplayType.SMALL,
        }"
        class="select-none m-auto"
        [src]="app.appIconURL"
        alt=""
      />
    } @placeholder {
      <div
        [ngClass]="{
          'big-image': displayType === DisplayType.BIG,
          'small-image': displayType === DisplayType.SMALL,
        }"
      ></div>
    }
  </a>
</article>
