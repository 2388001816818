import { Component, ViewChild } from '@angular/core';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { ResetStartpageService } from '../../services/reset-startpage-service/reset-startpage.service';
import { AppFinderDataService } from '../../services/appfinder-service/app-finder-data.service';
import { NavigationSetup } from '../../services/navigation-items-service/navigation-setup';
import { Router } from '@angular/router';
import { WelcomeWizardSeenService } from '../../services/welcome-wizard/welcome-wizard-seen.service';
import { FolderService } from '../../services/folder-service/folder.service';
import { FolderUiService } from '../../../pages/start-page/services/folder-ui.service';
import { WidgetUIService } from '../../../pages/start-page/services/widget-ui.service';

@Component({
  selector: 'reset-startpage-popup',
  standalone: true,
  imports: [DialogPopupComponent],
  templateUrl: './reset-startpage-popup.component.html',
  styleUrl: './reset-startpage-popup.component.scss',
})
export class ResetStartpagePopupComponent {
  @ViewChild('dialog') dialogPopupComponent!: DialogPopupComponent;

  public static instance: ResetStartpagePopupComponent;

  constructor(
    private router: Router,
    private resetStartPageService: ResetStartpageService,
    private appFinderDataService: AppFinderDataService,
    private welcomeWizardSeenService: WelcomeWizardSeenService,
    private folderService: FolderService,
    private folderUiService: FolderUiService,
    private widgetUiService: WidgetUIService,
  ) {
    ResetStartpagePopupComponent.instance = this;
  }

  openDialog(): void {
    this.dialogPopupComponent.openDialog();
  }

  doReset(): void {
    this.resetStartPageService.resetStartPage().subscribe(() => {
      this.router
        .navigate([NavigationSetup.START_PAGE.path], {
          onSameUrlNavigation: 'reload',
        })
        .then(() => {
          this.welcomeWizardSeenService.showWizard();
        });

      this.appFinderDataService.fetchAllApps().subscribe(() => {
        this.appFinderDataService.getAllApps().subscribe((_allApps) => {
          this.widgetUiService.apps.set(_allApps);
          this.folderService.getFolders().subscribe((_folders) => {
            this.folderUiService.folders = _folders;

            if (
              !this.folderUiService.getSelectedFolder()() ||
              this.folderUiService.folders.length > 0
            ) {
              this.folderUiService.setSelectedFolder(_folders[0]);
            }

            this.dialogPopupComponent.closeDialog();
          });
        });
      });
    });
  }
}
