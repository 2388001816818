<dialog
  class="dialog-container"
  [ngClass]="{
    'info-dialog': isInfoDialog,
    'auto-height': isAutoHeight,
    scroll: enableScrollWhenOverFlow,
  }"
  #dialog
  (click)="backgroundClick($event)"
>
  <div #dialogContent class="dialog-frame" (click)="stopPropagation($event)">
    <section class="title-wrapper">
      <div class="warning-icon-title-container">
        @if (displayWarningIcon) {
          <i
            class="a-icon boschicon-bosch-ic-alert-warning-filled warning-icon"
          ></i>
        }
        <h2 class="title">
          {{ title }}
        </h2>
      </div>

      <i
        class="cursor-pointer a-icon ui-ic-close close-icon"
        (click)="closeDialog()"
      ></i>
    </section>

    <section class="dialog-content mt-4 mb-4">
      <ng-content></ng-content>
    </section>

    <section>
      <div class="button-container">
        <app-button
          *ngIf="activeSecondaryButton"
          (click)="secondaryButtonClickedMethod()"
          [buttonLabel]="secondaryButtonName"
          [buttonType]="ButtonTypeEnum.SECONDARY"
        />
        <app-button
          *ngIf="activePrimaryButton"
          (click)="primaryButtonClickedMethod()"
          [buttonLabel]="primaryButtonName"
          [buttonType]="ButtonTypeEnum.PRIMARY"
          [isDisabled]="primaryButtonDisabled"
        />
      </div>
    </section>
  </div>
</dialog>
