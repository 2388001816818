import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppInterface,
  ClickOnAppBehavior,
  DisplayType,
} from '../../interfaces/app.interface';
import { AppIconComponent } from './app-icon/app-icon.component';
import { AddRemoveAppButtonComponent } from './add-remove-button/add-remove-app-button.component';
import { Router, RouterLink } from '@angular/router';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { FormsModule } from '@angular/forms';
import { NavigationItemsService } from '../../services/navigation-items-service/navigation-items.service';
import { UrlService } from '../../services/url-service/url.service';
import { ScrollService } from '../../services/scroll-service/scroll.service';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
  selector: 'app',
  standalone: true,
  imports: [
    CommonModule,
    AppIconComponent,
    AddRemoveAppButtonComponent,
    RouterLink,
    DialogPopupComponent,
    FormsModule,
    TooltipDirective,
  ],
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements AfterContentChecked {
  @Input({ required: true }) app!: AppInterface;
  @Input() displayType: DisplayType = DisplayType.SMALL;
  @Input() showAddRemoveButton: boolean = true;
  @Input() clickOnAppBehavior: ClickOnAppBehavior =
    ClickOnAppBehavior.OPEN_APP_DETAILS;
  @Input() isAppFinderPage: boolean = false;
  @Input() recommendSectionName: string = '';

  protected readonly DisplayType = DisplayType;
  @ViewChild('titleElement') titleElement!: ElementRef;
  @Input() isFromDetailPage: boolean = false;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private navigationItems: NavigationItemsService,
    private router: Router,
    private urlService: UrlService,
    private scrollService: ScrollService,
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  openAppURL(): void {
    this.urlService.openURL(this.app.appURL);
  }

  async openAppDetail(): Promise<void> {
    this.scrollService.savePosition(this.router.url);

    await this.router.navigate([
      this.navigationItems.getAppDetailPage().routerLink,
      this.app.appId,
    ]);
    window.scroll(0, 0);
  }

  protected onAppClicked() {
    switch (this.clickOnAppBehavior) {
      case ClickOnAppBehavior.OPEN_APP_DETAILS:
        this.openAppDetail().then();
        break;
      case ClickOnAppBehavior.OPEN_APP_BY_URL:
        this.openAppURL();
        break;
      case ClickOnAppBehavior.NO_BEHAVIOR:
        return;
      default:
        return;
    }
  }

  protected readonly ClickOnAppBehavior = ClickOnAppBehavior;
}
