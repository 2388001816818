import { Injectable } from '@angular/core';
import { ToastMessageService } from '../../../pages/app-finder-page/services/toast-message-service/toast-message.service';
import { isValidUrl } from '../../validators/url.validator';

const HTTPS_PROTOCOL_PREFIX = 'https://';
@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private toastMessageService: ToastMessageService) {}
  openURL(
    _link?: string,
    _config?: { target?: string; features?: string; isSecure?: boolean },
  ): void {
    if (!_link) {
      // no url -> url invalid -> notify
      this.notifyUrlError();
    } else if (_config?.isSecure) {
      // is secure -> just open as-is
      window.open(_link, _config?.target ?? '_blank', _config?.features);
    } else {
      let url = _link;
      // invalid url -> maybe be it helps to add https protocol a prefix
      if (!isValidUrl(url)) {
        url = HTTPS_PROTOCOL_PREFIX + url;
      }
      if (isValidUrl(url)) {
        // valid url -> open it
        window.open(url, _config?.target ?? '_blank', _config?.features);
      } else {
        // url invalid -> notify
        this.notifyUrlError();
      }
    }
  }

  private notifyUrlError() {
    this.toastMessageService.openToastDialog({
      primaryMessage: 'Could not open URL.',
      secondMessage: 'Target URL is probably incorrect or malicious.',
    });
  }
}
