import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';
import { AppInstanceService } from '../../../services/appinstance-service/appinstance.service';
import { FolderInterface } from '../../../interfaces/folder.interface';
import { AppInterface } from '../../../interfaces/app.interface';
import { FolderService } from '../../../services/folder-service/folder.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FolderStateService } from '../../../services/folder-service/folder-state.service';
import { ToastMessageService } from 'src/app/pages/app-finder-page/services/toast-message-service/toast-message.service';
import { ToastMessageInterface } from 'src/app/core/interfaces/toast-message.interface';
import { LogEventService } from 'src/app/core/services/log-event-service/log-event.service';

@Component({
  selector: 'add-app-to-folder-dialog',
  standalone: true,
  imports: [CommonModule, DialogPopupComponent, ReactiveFormsModule],
  templateUrl: './add-app-to-folder-dialog.component.html',
  styleUrls: ['./add-app-to-folder-dialog.component.scss'],
})
export class AddAppToFolderDialogComponent {
  @ViewChild('addAppToFolderDialog', { static: true })
  dialog!: DialogPopupComponent;
  @Input({ required: true }) app!: AppInterface;
  @Input() recommendSectionname: string = '';
  @Input() isFromDetailPage: boolean = false;

  protected selectableFolders: FolderInterface[] = [];
  protected folderControl: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
  });

  constructor(
    protected folderService: FolderService,
    private appInstanceService: AppInstanceService,
    private folderStateService: FolderStateService,
    private toastMessageService: ToastMessageService,
    private logEventService: LogEventService,
  ) {}

  public openAddAppToFolderDialog() {
    this.folderService.getFolders().subscribe((_folders) => {
      this.selectableFolders = _folders;
      this.folderControl.setValue(
        this.folderStateService.getCurrentFolderId() || _folders[0].id,
      );
      if (_folders.length > 1) {
        this.dialog.openDialog();
      } else {
        this.addAppToFolder();
      }
    });
  }

  protected addAppToFolder() {
    this.app.folderId = this.folderControl.getRawValue();
    if (!this.app.isBookmark) {
      this.app.isBookmark = false;
    }

    this.appInstanceService.addAppInstance(this.app).subscribe(() => {
      this.logEventService.logServer(
        'add',
        this.filterDataLogEvent(),
        this.app.appId?.toString(),
      );
      this.showAppAddedMessage(this.app);
    });
  }

  protected getUniqueKey(_index: number): string {
    return this.app.appId + '-' + _index + '-id';
  }

  private showAppAddedMessage(_app: AppInterface) {
    const msg: ToastMessageInterface = {
      primaryMessage: _app?.title,
      secondMessage: ` has been added successfully.`,
    };
    this.toastMessageService.openToastDialog(msg);
  }

  private filterDataLogEvent(): string {
    if (this.isFromDetailPage) {
      return 'Page: App Detail';
    } else {
      return `Page: App Finder, Section:${this.recommendSectionname ? ` Recommendations ${this.recommendSectionname}` : ' All Apps'}`;
    }
  }
}
