import { Injectable } from '@angular/core';
import { UserConfigurationService } from '../userconfiguration/user-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavigationService {
  public KEY_SIDE_NAVIGATION_OPEN = 'SIDE_NAVIGATION_OPEN';

  private isOpenSideNav: boolean = true;

  constructor(private userConfigurationService: UserConfigurationService) {
    this.isOpenSideNav = this.userConfigurationService.getOrDefault(
      this.KEY_SIDE_NAVIGATION_OPEN,
      true,
    );
  }

  isSideNavOpen(): boolean {
    return this.isOpenSideNav;
  }

  toggleSideNav(): void {
    this.isOpenSideNav = !this.isOpenSideNav;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, this.isOpenSideNav)
      .subscribe();
  }

  openSideNav(): void {
    this.isOpenSideNav = true;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, true)
      .subscribe();
  }

  closeSideNav(): void {
    this.isOpenSideNav = false;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, false)
      .subscribe();
  }
}
