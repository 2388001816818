import { AbstractControl, ValidationErrors } from '@angular/forms';

export const URL_VALIDATOR_ERROR_KEY: string = 'urlInvalid';

export function secureUrl(control: AbstractControl): ValidationErrors | null {
  const validationError: { [key: string]: boolean } = {};
  validationError[URL_VALIDATOR_ERROR_KEY] = true;
  // empty url is secure
  if (!control.value) return null;
  return isValidUrl(control.value) ? null : validationError;
}
export function isValidUrl(_url: string): boolean {
  if (!_url) {
    return false;
  }
  try {
    const targetUrl = new URL(_url);
    const isJsProtocol = targetUrl.protocol === 'javascript:';
    return !isJsProtocol;
  } catch (error) {
    return false;
  }
}
