<article #buttonContainer class="add-remove-button-container">
  @if (getState() === AddRemoveState.ADD) {
    <button
      (click)="addAppToFolder()"
      class="add"
    >
      <i class="boschicon-bosch-ic-add-frame"></i>
      Add
    </button>
  } @else if (getState() === AddRemoveState.REMOVE) {
    <button
      (click)="removeAppInstance()"
      class="remove"
    >
      Remove
    </button>
  }
</article>

@defer (on interaction(buttonContainer)) {
  <add-app-to-folder-dialog
    [isFromDetailPage]="isFromDetailPage"
    [recommendSectionname]="recommendSectionname"
    [app]="app"
    #addAppToFolderDialog
  />
}
