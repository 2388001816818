import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { PlatformService } from '../../../core/services/platform-service/platform.service';
import {
  AppInterface,
  ETileType,
} from '../../../core/interfaces/app.interface';
import { FolderUiService } from './folder-ui.service';
import { FolderInterface } from '../../../core/interfaces/folder.interface';
import { AppInstanceService } from '../../../core/services/appinstance-service/appinstance.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetUIService {
  N_ADDITIONAL_CELLS = 30;
  public apps: WritableSignal<AppInterface[]> = signal([]);

  public appsInFolder: Signal<AppInterface[]> = computed(() =>
    this.apps().filter(
      (_app) =>
        _app.inDashboard &&
        _app.folderId === this.folderUIService.getSelectedFolder()()?.id,
    ),
  );

  public mobileApps: Signal<AppInterface[]> = computed(() =>
    this.appsInFolder()
      .filter((_app) => _app.supportsMobile)
      .sort((_a, _b) => _a.posX! - _b.posX!)
  );

  public cells: Signal<number[]> = computed(() => {
    const nCells: number =
      Math.max(...this.appsInFolder().map((_app) => _app.posX ?? 0)) +
      this.N_ADDITIONAL_CELLS;
    const _cells: number[] = [];
    for (let i = 0; i < nCells; i++) {
      _cells.push(i);
    }
    return _cells;
  });

  constructor(
    private platformService: PlatformService,
    private folderUIService: FolderUiService,
    private appInstanceService: AppInstanceService,
  ) {}

  public isSmall(_app: AppInterface): boolean {
    return (
      this.platformService.isMobile() ||
      _app.appType === ETileType.Static ||
      _app.appType === ETileType.Card
    );
  }

  updateWidgetPosition(_app: AppInterface, _newPos: number) {
    this.apps.update((_apps) => {
      const appToMutate = _apps.find(
        (_appInArray) => _appInArray.appInstanceId === _app.appInstanceId,
      ) as AppInterface;
      appToMutate.posX = _newPos;
      return [..._apps];
    });
  }

  updateWidgetFolder(_app: AppInterface, _newFolder: FolderInterface) {
    this.apps.update((_apps) => {
      const appToMutate = _apps.find(
        (_appInArray) => _appInArray.appInstanceId === _app.appInstanceId,
      ) as AppInterface;
      appToMutate.folderId = _newFolder.id;
      return [..._apps];
    });
  }

  forceAllAppsSignalRecomputation(): void {
    this.apps.update((_apps) => [..._apps]);
  }

  getAppsInFolder(folderId: string): AppInterface[] {
    return this.apps().filter(
      (app) => app.inDashboard && app.folderId === folderId,
    );
  }

  getWidgetAtCell(_cellId: number): AppInterface | undefined {
    return this.appsInFolder().find((_app) => _app.posX === _cellId);
  }

  switchWidgetPositions(_app1: AppInterface, _app2: AppInterface) {
    if (_app1.posX === _app2.posX) return;
    this.appInstanceService
      .switchPositionOfApps(_app1, _app2)
      .subscribe((_switchPosition) => {
        this.updateWidgetPosition(
          _app2,
          _switchPosition.secondAppInstance.posX as number,
        );
        this.updateWidgetPosition(
          _app1,
          _switchPosition.firstAppInstance.posX as number,
        );
      });
  }

  isEmptyAppsMobileInFolder(): boolean {
    return this.mobileApps().length === 0;
  }
}
