import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppButtonComponent } from '../app-button/app-button.component';
import { PlatformService } from '../../services/platform-service/platform.service';
import { ButtonTypeEnum } from '../../enums/buttonType.enum';
import { ScrollService } from '../../services/scroll-service/scroll.service';
import { DragDropStateService } from '../../services/drag-and-drop-service/drag-and-drop-state.service';

@Component({
  selector: 'dialog-popup',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss'],
})
export class DialogPopupComponent implements AfterViewInit {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) activePrimaryButton: boolean = true;
  @Input({ required: true }) activeSecondaryButton: boolean = true;
  @Input() isInfoDialog: boolean = false;
  @Input() enableScrollWhenOverFlow: boolean = false;
  @Input() primaryButtonDisabled = false;
  @Input() primaryButtonName: string = '';
  @Input() secondaryButtonName: string = '';
  @Input() isAutoHeight = false;
  @Input() displayWarningIcon!: boolean;

  @ViewChild('dialog') dialogRef?: ElementRef;
  @ViewChild('dialogContent') dialogContentRef?: ElementRef;

  @Output() primaryButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryButtonClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() outsideContentClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() dialogEscapeCloseClicked: EventEmitter<void> =
    new EventEmitter<void>();

  constructor(
    protected platformService: PlatformService,
    private scrollService: ScrollService,
    private dragDropStateService: DragDropStateService,
  ) { }

  ngAfterViewInit(): void {
    this.dialogRef?.nativeElement.addEventListener('cancel', () => {
      this.dialogEscapeCloseClicked.emit();
    });
  }

  public openDialog() {
    if (!this.scrollService.isFinishScroll()) {
      return;
    }
    this.scrollService.disableScroll();
    this.dragDropStateService.setDialogOpen(true);
    this.dialogRef?.nativeElement.showModal();
  }

  public closeDialog() {
    this.dialogRef?.nativeElement.close();
    this.scrollService.enableScroll();
    this.dragDropStateService.setDialogOpen(false);
  }

  public primaryButtonClickedMethod(): void {
    this.closeDialog();
    this.primaryButtonClicked.emit();
  }

  public secondaryButtonClickedMethod(): void {
    this.closeDialog();
    this.secondaryButtonClicked.emit();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  public backgroundClick(event: Event) {
    this.outsideContentClicked.emit();
    if (!this.platformService.isMobile()) {
      this.closeDialog();
      this.stopPropagation(event);
    }
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
