import { Injectable } from '@angular/core';
import { SearchByEnum } from '../../enums/search-by.enum';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor() {}

  compareFunc(
    _searchBy: SearchByEnum,
  ): (_elementProperty: string, _query: string) => boolean {
    return _searchBy === SearchByEnum.INCLUDES
      ? (_elementProperty: string, _query: string): boolean => {
          return _elementProperty
            .toLocaleLowerCase()
            .includes(_query.toLocaleLowerCase());
        }
      : (_elementProperty: string, _query: string): boolean => {
          return _elementProperty
            .toLocaleLowerCase()
            .startsWith(_query.toLocaleLowerCase());
        };
  }

  search(
    _query: string,
    elements: any[],
    _properties: string[] = [],
    _searchBy: SearchByEnum = SearchByEnum.INCLUDES,
  ): any[] {
    if (!elements.length || !_query.length) {
      return elements;
    }
    const compareFunction = this.compareFunc(_searchBy);
    const returnArray = [];
    _properties = _properties.length
      ? _properties
      : Object.getOwnPropertyNames(elements[0]);

    for (const element of elements) {
      for (const property of _properties) {
        if (
          this.fieldMatchesQuery(_query, element[property], compareFunction)
        ) {
          returnArray.push(element);
          break;
        }
      }
    }
    return returnArray;
  }

  private fieldMatchesQuery(
    _query: string,
    _elementProperty: any,
    _compareFunc: (_elementProperty: string, _query: string) => boolean,
  ): boolean {
    if (!_elementProperty) {
      return false;
    }
    if (typeof _elementProperty === 'object') {
      const properties = Object.getOwnPropertyNames(_elementProperty);
      for (const property of properties) {
        return this.fieldMatchesQuery(
          _query,
          _elementProperty[property],
          _compareFunc,
        );
      }
      return false;
    }

    if (typeof _elementProperty === 'string') {
      return _compareFunc(_elementProperty, _query);
    }
    if (typeof _elementProperty === 'number') {
      return _compareFunc(_elementProperty + '', _query);
    }
    return false;
  }
}
