import { Component, computed, Input, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchBarComponent } from '../../../../core/components/search-bar/search-bar.component';
import { AppInterface } from '../../../../core/interfaces/app.interface';
import { WidgetUIService } from '../../services/widget-ui.service';
import { SearchResultComponent } from '../../../../core/components/search-result/search-result.component';
import { PlatformService } from '../../../../core/services/platform-service/platform.service';
import { SearchResults } from '../../../../core/interfaces/search-results.interface';
import { StartPageAppsSearchService } from '../../../../core/services/search-startpage-service/start-page-apps-search.service';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'start-page-search-bar',
  standalone: true,
  imports: [
    SearchBarComponent,
    SearchResultComponent,
    JsonPipe
  ],
  templateUrl: './start-page-search-bar.component.html',
  styleUrl: './start-page-search-bar.component.scss'
})
export class StartPageSearchBarComponent {

  @Input({ required: true })
  public isMinimal!: boolean;


  public searchQuery: WritableSignal<string> = signal('');
  protected allApps: Signal<AppInterface[]> = computed(() => this.widgetUIService.apps());

  protected filteredApps: Signal<SearchResults> = computed(() =>
    this.startPageAppsSearchService.searchStartPage(
      this.searchQuery(),
      this.allApps()
    )
  );


  protected queryFormControl: FormControl<string | null> =
    new FormControl<string>('');


  protected onFocusSearchStartPage = false;


  constructor(private router: Router,
              private widgetUIService: WidgetUIService,
              private platformService: PlatformService,
              private startPageAppsSearchService: StartPageAppsSearchService) {
    this.addRouterEvent();
  }

  private addRouterEvent(): void {
    this.router.events.subscribe(() => {
      // clear searchQuery when change navigate
      this.searchQuery.set('');
    });
  }


  updateSearchQuery(_query: string): void {
    this.searchQuery.set(_query);
  }

  focusChangeEvent(_event: boolean): void {
    this.onFocusSearchStartPage = _event;
  }

  openSearchResult(): boolean | number {
    if (this.platformService.isMobile()) {
      return this.searchQuery().length;
    } else {
      return this.searchQuery().length > 2 && this.onFocusSearchStartPage;
    }
  }

}
