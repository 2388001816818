import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { AppFinderFilters } from '../../interfaces/filters.interface';
import { TagFilterInterface } from '../../interfaces/tags-filter.interface';
import { Subject, takeUntil } from 'rxjs';
import { SearchService } from 'src/app/core/services/search-service/search.service';
import { AppInterface } from 'src/app/core/interfaces/app.interface';
import { ScrollService } from '../../../../core/services/scroll-service/scroll.service';
import { SearchByEnum } from 'src/app/core/enums/search-by.enum';
import { SideNavigationService } from 'src/app/core/services/navigation-service/side-navigation.service';
import {
  NavigationFilterStateService
} from "../../../../core/services/navigation-filter-state/navigation-filter-state.service";

@Injectable({
  providedIn: 'root',
})
export class AppFinderFilterService {
  searchByEnum = SearchByEnum;
  filterOpened: boolean = false;
  category: WritableSignal<string> = signal('');
  clientSupport: WritableSignal<string> = signal('');
  tags: WritableSignal<string[]> = signal([]);
  availability: WritableSignal<string[]> = signal([]);

  clearFiltersEvent$: Subject<void> = new Subject<void>();

  private filters: Signal<AppFinderFilters> = computed(() => {
    return {
      category: this.category(),
      clientSupport: this.clientSupport(),
      tags: this.tags(),
      availability: this.availability(),
    };
  });

  numberOfFiltersApplied: Signal<number> = computed(() => {
    return Object.values(this.filters())
      .flatMap((_f) => _f)
      .filter((_f) => !!_f).length;
  });

  isSideNavigationOpen = computed(() => this.navigationFilterStateService.menuBarOpen());

  constructor(
    private searchService: SearchService,
    private scrollService: ScrollService,
    private sideNavigationService: SideNavigationService,
    private navigationFilterStateService: NavigationFilterStateService,
  ) { }

  openFilters(): void {
    this.filterOpened = true;
    this.scrollService.disableScroll();

    this.navigationFilterStateService.setMenuBarState(this.sideNavigationService.isSideNavOpen());

    // We have to close the sideNav here, due to sideNav behaviour
    this.sideNavigationService.closeSideNav();
  }

  hasActiveFilters(): boolean {
    const filters = this.filters();
    return (
      (filters.category?.length ?? 0) > 0 ||
      (filters.clientSupport?.length ?? 0) > 0 ||
      (filters.tags?.length ?? 0) > 0
    );
  }

  closeFilters(): void {
    this.filterOpened = false;
    this.scrollService.enableScroll();

    if (this.isSideNavigationOpen()) {
      this.sideNavigationService.openSideNav();
    }
    else {
      this.sideNavigationService.closeSideNav();
    }
  }

  setCategory(_category: string): void {
    this.category.set(_category);
  }

  setClientSupport(_clientSupport: string): void {
    this.clientSupport.set(_clientSupport);
  }

  setTags(_tags: TagFilterInterface[]): void {
    this.tags.set(
      _tags.filter((_tag) => _tag.selected).map((_tag) => _tag.name),
    );
  }

  setAvailability(_availability: string[]): void {
    this.availability.set(_availability);
  }

  getFilters(): Signal<AppFinderFilters> {
    return this.filters;
  }

  clearFilters(): void {
    this.setCategory('');
    this.setClientSupport('');
    this.setTags([]);
    this.setAvailability([]);
  }

  clearFiltersListener(destroyRef: Subject<void>, action: () => void): void {
    this.clearFiltersEvent$
      .asObservable()
      .pipe(takeUntil(destroyRef))
      .subscribe(action);
  }

  filterApps(allApps: Signal<AppInterface[]>): AppInterface[] {
    const { category, clientSupport, tags, availability } = this.filters();

    let filteredApps = allApps();
    const anyClientApps = this.filterAppsByProperty(
      'ANY_CLIENT',
      filteredApps,
      'clientSupport',
    );

    filteredApps = this.filterAppsByProperty(
      clientSupport,
      filteredApps,
      'clientSupport',
    );

    // Merging anyClientApps with filteredApps
    filteredApps = anyClientApps.reduce(
      (_mergedApps: AppInterface[], _currentApp: AppInterface) => {
        if (!_mergedApps.some((app) => app.appId === _currentApp.appId)) {
          _mergedApps.push(_currentApp);
        }
        return _mergedApps;
      },
      filteredApps,
    );

    filteredApps = this.filterAppsByProperty(
      category === 'All categories' ? null : category,
      filteredApps,
      'category',
    );

    if (tags.length) {
      filteredApps = filteredApps.filter((_app) => {
        return tags.every((_tag) => _app.keywords?.includes(_tag));
      });
    }

    if (availability.length) {
      filteredApps = filteredApps.filter((_app) => {
        return availability.some((_country) => {
          return _app.countries?.includes(_country) || !_app.countries?.length;
        });
      });
    }

    return filteredApps;
  }

  filterAppsByProperty<K extends keyof AppInterface>(
    filterQuery: string | null,
    apps: AppInterface[],
    property: K,
  ): AppInterface[] {
    if (filterQuery) {
      return this.searchService.search(
        filterQuery,
        apps.filter(app => app[property]), // Exclude apps with null or undefined category
        [property as string],
        this.searchByEnum.INCLUDES,
      );
    }
    return apps;
  }
}
