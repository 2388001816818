<dialog-popup
  [activePrimaryButton]="true"
  [activeSecondaryButton]="true"
  [title]="'Add this app to folder'"
  primaryButtonName="Add"
  secondaryButtonName="Cancel"
  (primaryButtonClicked)="addAppToFolder()"
  #addAppToFolderDialog
>
  <div class="scroll-container display-rows">
    <form class="radio-buttons-layout">
      @for (folder of selectableFolders; track folder.id; let i = $index) {
        <div class="a-radio-button">
          <input
            type="radio"
            [value]="folder.id"
            [formControl]="folderControl"
            name="folder"
            [id]="getUniqueKey(i)"
          />
          <label [for]="getUniqueKey(i)">
            {{ folder.name }}
          </label>
        </div>
      }
    </form>
  </div>
</dialog-popup>
