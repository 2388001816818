import { Component, Input, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppInterface,
  ClickOnAppBehavior,
} from '../../interfaces/app.interface';
import { Router } from '@angular/router';
import { UserService } from '../../services/user-service/user.service';
import { NavigationItemVisibilityService } from '../../services/navigation-service/navigation-item-visibility.service';
import { CurrentNavigationItemService } from '../../services/navigation-service/current-navigation-item.service';
import { SideNavigationService } from '../../services/navigation-service/side-navigation.service';
import { SearchResults } from '../../interfaces/search-results.interface';
import { ApplicationComponent } from '../app/application.component';
import { SearchResultsService } from 'src/app/pages/start-page/services/search-results.service';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { LogEventService } from '../../services/log-event-service/log-event.service';

@Component({
  selector: 'search-result',
  standalone: true,
  imports: [CommonModule, ApplicationComponent, BookmarkComponent],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  searchResults = {
    finder: [],
    startPage: [],
    bookmark: [],
  };
  @Input() filteredApps: Signal<SearchResults> = signal(this.searchResults);
  @Input() openSearchResult: boolean | number = false;

  constructor(
    private router: Router,
    protected userService: UserService,
    protected navigationService: NavigationItemVisibilityService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    protected sideNavigationService: SideNavigationService,
    protected searchResultsService: SearchResultsService,
    private logEventService: LogEventService,
  ) {}

  logAppSearchResult(_app: AppInterface): void {
    this.logEventService.logServer('app', 'searchResult', _app.appId + '');
  }

  logBookmarkResult(_app: AppInterface): void {
    this.logEventService.logServer(
      'bookmark',
      'searchResult',
      _app.appInstanceId,
    );
  }

  closeSearchResults(): void {
    this.searchResultsService.shouldOpenSearch.next(false);
    this.openSearchResult = false;
  }

  showSearchResult() {
    if (typeof this.openSearchResult === 'boolean') {
      return this.openSearchResult;
    } else {
      return this.openSearchResult > 2;
    }
  }

  protected readonly ClickOnAppBehavior = ClickOnAppBehavior;
}
