import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, inject } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpErrorResponse, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { catchError, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { AuthService } from './core/services/auth-service/auth.service';
import { GlobalErrorHandler } from './core/error-handlers/global-error-handler.class';
import { UserService } from './core/services/user-service/user.service';
import { HttpConfigInterceptor } from './core/interceptors/http-config-interceptor/http-config.interceptor';
import { UserConfigurationService } from './core/services/userconfiguration/user-configuration.service';

function initializer(): () => Observable<void> {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const userConfiguration = inject(UserConfigurationService);

  return () =>
    authService.checkAuthentication().pipe(
      take(1),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // you are not logged in, redirect to AzureAD (Azure Entra)
            authService.redirectToAzureAd();
          }
        }
        return throwError(() => err);
      }),
      tap((_user) => {
        userService.setUser(_user);
      }),
      switchMap(() => userConfiguration.fetchUserConfiguration())
    );
}

const initializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializer,
  multi: true
};

const globalErrorHandler = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    initializerProvider,
    globalErrorHandler
  ]
};
